import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cx from './Message.module.css';

import { Copy, GptzatorAvatar } from '../../../../lib/getIcon';
import { UserOutlined } from '@ant-design/icons';
import { TMessage, TMessageChoicesItem } from '../../model/types';
import { Annotations } from '../Annotations';
import { removeBraces } from 'shared/lib/removeBraces';
import { mdToHtml } from 'shared/lib/mdToHtml';
import { StapleIcon, PaperClipOutlined } from 'shared/ui/Icons';
import { message as antMessage } from 'antd';
import { useTranslate } from '@ayub-begimkulov/i18n';
import cn from 'classnames';

interface IMessage {
	message: TMessage;
	handleImgClick?: (imgSrc: string) => void;
	avatarUrl?: string;
}

const MessageComponent = ({ message, handleImgClick, avatarUrl }: IMessage) => {
	const t = useTranslate();
	const [messageApi, contextMessageHolder] = antMessage.useMessage();
	const [textContents, setTextContents] = useState<Record<string, string>>({});
	const contentRef = useRef<HTMLDivElement>(null);
	const { type, choices } = message;
	useEffect(() => {
		const createHtmlStr = async (contentItem: TMessageChoicesItem) => {
			try {
				const htmlStr = await mdToHtml(
					contentItem.annotations
						? renderMessageWithAnnotations(contentItem.content, contentItem.annotations)
						: contentItem.content
				);
				setTextContents((prev) => ({ ...prev, [contentItem.id]: htmlStr }));
			} catch (e) {
				setTextContents((prev) => ({ ...prev, [contentItem.id]: contentItem.content }));
			}
		};

		Promise.all(choices.map((contentItem) => createHtmlStr(contentItem))).catch((error) => {
			console.error('create HTML error', error);
		});
	}, [choices]);

	const onCopyClick = useCallback(async () => {
		if (contentRef.current) {
			const html = contentRef.current.innerHTML;

			try {
				await navigator.clipboard.write([
					new ClipboardItem({
						'text/html': new Blob([html], { type: 'text/html' }),
						'text/plain': new Blob([contentRef.current.innerText], { type: 'text/plain' })
					})
				]);
				messageApi.success({
					content: <span>{t('copied')}</span>,
					duration: 3
				});
			} catch (err) {
				messageApi.error({
					content: <span>{t('copied.error')}</span>,
					duration: 3
				});
			}
		}
	}, [choices, contentRef.current]);

	const onImgClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		if (e.target && e.target instanceof HTMLImageElement && handleImgClick) {
			const srcValue = e.target.src;
			handleImgClick(srcValue);
		}
	};
	const renderContent = (contentItem: TMessageChoicesItem) => {
		const text = textContents[contentItem.id] as string;
		return (
			<div key={contentItem.id} className='max-w-full'>
				<div dangerouslySetInnerHTML={{ __html: text }} onClick={onImgClick} />
				{!!contentItem.annotations && <Annotations annotations={contentItem.annotations} />}
			</div>
		);
	};

	const isAllTextContentTransformed = choices.every((textContent) => textContents[textContent.id]);

	const renderMessageWithAnnotations = (text: string, annotations: Record<string, string>) => {
		let messageWithAnnotations: string = text;

		for (const key in annotations) {
			const annotation = removeBraces(key) as string;
			const annotationIndex = parseInt(annotation, 10);
			messageWithAnnotations = messageWithAnnotations.replace(annotation, `${annotationIndex}`);
		}
		return messageWithAnnotations;
	};

	const renderMessage = useMemo(() => {
		if (type === 'userChangeContext') {
			return (
				<div className='w-full max-w-[800px] flex relative justify-end'>
					<div className={cx.inner}>
						<StapleIcon className='text-transparent text-2xl stroke-black' />

						{choices.map((contentItem) => (
							<div className='ml-2 flex flex-col w-full pl-0' key={contentItem.id}>
								{contentItem.content}
							</div>
						))}
					</div>
				</div>
			);
		}
		if (type === 'userTextFile') {
			return (
				<div className='w-full max-w-[800px] flex relative justify-end'>
					<div className={cx.inner}>
						<PaperClipOutlined className='opacity-[0.45] text-2xl text-black' />

						<div className='ml-2 flex flex-col w-full pl-0'>{message.file.originalFilename}</div>
					</div>
				</div>
			);
		}

		return (
			<div className={`w-full max-w-[800px] flex ${type === 'gpt' ? 'justify-start' : 'justify-end'}`}>
				<div className={cn(cx.inner, { '!bg-gray-bg': type === 'gpt' })}>
					{type === 'gpt' ? (
						<div className='min-w-[32px] w-[32px] h-[32px] rounded-full flex items-center justify-center overflow-hidden mr-4'>
							<GptzatorAvatar />
						</div>
					) : avatarUrl ? (
						<div className='rounded-full flex item-center justify-center min-w-[32px] w-[32px] h-[32px] overflow-hidden mr-4'>
							<img src={avatarUrl} alt='avatar' style={{ width: '100%' }} />
						</div>
					) : (
						<UserOutlined className='min-w-[32px] h-[32px] rounded-full border border-solid border-primary text-primary flex items-center justify-center text-3xl overflow-hidden mr-4' />
					)}
					<div className='flex flex-col w-full pl-0'>
						<div className='min-w-[20px] w-[20px] h-[20px] flex items-center justify-center absolute right-4 top-4'>
							<Copy className={cx.copy} onClick={() => onCopyClick()} />
						</div>
						{type === 'gpt' && isAllTextContentTransformed ? (
							<div ref={contentRef}>{choices.map((contentItem) => renderContent(contentItem))}</div>
						) : (
							<div ref={contentRef}>
								{choices.map((contentItem) => (
									<div key={contentItem.id} className='max-w-full'>
										{contentItem.content}
									</div>
								))}
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}, [avatarUrl, type, choices, isAllTextContentTransformed]);

	return (
		<div className={cx.root}>
			{contextMessageHolder}
			{renderMessage}
		</div>
	);
};

export const Message = memo(MessageComponent);
